'use client'

import { useEffect } from 'react'
import { useToast } from '~@Lib/use-toast'
import { ToastAction } from '~@Lib/toast'
import { Toaster } from '~@Lib/toaster'
import { IToasterSystem } from '~@Types/components/IToasterSystem'

export default function ToasterSystem({
  variant,
  title,
  description,
  buttonLabel,
}: IToasterSystem) {
  const { toast } = useToast()

  useEffect(() => {
    toast({
      title,
      variant,
      description,
      action: buttonLabel ? (
        <ToastAction altText="Try again">{buttonLabel}</ToastAction>
      ) : undefined,
    })
  }, [toast, title, variant, description, buttonLabel])

  return <Toaster />
}
