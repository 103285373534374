import { ISectionWall, ISectionWallCms } from '~@Types/components/ISectionWall'
import { TeaserCarAdapter } from './TeaserCardAdapter'

export const NewsAdapter = (response: ISectionWallCms): ISectionWall => {
  return {
    displayBTN: true,
    items: response.items.data.map((itemCms) =>
      TeaserCarAdapter('news', itemCms)
    ),
  }
}
