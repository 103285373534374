'use client'

import React from 'react'

import BlockContent from '~@Organisms/block-content/block-content'
import TeaserDownload from '~@Molecules/teaser-download/teaser-download'

import { ISectionDownloads } from '~@Types/components/IDownload'

import { useGTM } from '~@Hooks/useGTM'

const SectionDownloads = ({ title, list }: ISectionDownloads) => {
  const { trackDownload } = useGTM()

  const handleDownloadClick = (docName: string, docType: string) => {
    trackDownload(docName, docType)
  }

  return (
    <BlockContent className="SectionDownloads" title={title}>
      <ul className="SectionDownloads-list">
        {list.map((item, index) => {
          const formattedItem = {
            ...item,
            link: {
              ...item.link,
              onClick: () => handleDownloadClick(item.title, item.type),
            },
          }
          return (
            <li
              key={index}
              className="SectionDownloads-item border-b-xs border-b-solid border-grey-light"
            >
              <TeaserDownload {...formattedItem} />
            </li>
          )
        })}
      </ul>
    </BlockContent>
  )
}

export default SectionDownloads
