'use client'

import { useEffect, useState } from 'react'

// Components imports
import EditorialWysiwygOriginal from '~@Organisms/editorial-wysiwyg/editorial-wysiwyg'

// Types imports
import { IEditorialWysiwygCms } from '~@Types/components/IEditorialWysiwyg'

// Adapters and helpers imports
import { EditorialWysiwygAdapter } from '~@Adapters/EditorialWysiwygAdapter'
import { formatError } from '@/helpers/formatError'

/**
 * EditorialWysiwygCms Component
 * Client-side rendered WYSIWYG editor with error handling
 * @param props - The component props of type IEditorialWysiwygCms
 */
const EditorialWysiwygCms = (props: IEditorialWysiwygCms) => {
  const [isMounted, setIsMounted] = useState(false)

  useEffect(() => {
    setIsMounted(true)
  }, [])

  try {
    const adaptedProps = EditorialWysiwygAdapter(props)

    if (!isMounted) {
      return <div className="my-4xl" />
    }

    return (
      <div className="my-4xl">
        <EditorialWysiwygOriginal {...adaptedProps} />
      </div>
    )
  } catch (e) {
    formatError(e, 'EditorialWysiwygCms')
    return null
  }
}

export default EditorialWysiwygCms
