import { ITeaserStandard } from '~@Types/components/ITeaserStandard'
import { getEditoPages } from '~@Services/EditoService'
import { getNewsPages } from '~@Services/NewsService'
import { getCaseStudyPages } from '~@Services/CaseStudyService'
import { getPressReleasePages } from '~@Services/PressReleaseService'
import { getSolutionPages } from '~@Services/SolutionService'
import { getWhitePaperPages } from '~@Services/WhitePaperService'
import { getSubsidiaryPages } from '~@Services/SubsidiaryService'
import { getSkillPages } from '~@Services/SkillService'
import { getDocumentPages } from '~@Services/DocumentsService'
import { getFinancialDocumentPages } from '~@Services/FinancialDocumentsService'
import { TGetCollectionTypeReturn } from '~@Types/api/TCollectionType'
import { components } from '~@Types/api/strapi-schema'

export interface ISearchPageClient {
  locale: string
  search_term: string
  selectedFilters: string[]
  initial_search: IGetSearchResults
  initial_results: ITeaserStandard[]
  filterList: { label: string; id: string }[]
  paginationSetup: {
    currentPage: number
    pageSize: number
    numberOfPages: number
  }
}

export interface IGetSearchResults {
  results: IAggregatedResult[]
  actualPage: number
  numberOfPages: number
  numberOfResults: number
  params: IGetSearchParams
}

/**
 * Interface for search parameters
 * @param search_term - The text to search for in titles
 * @param filters - Array of content types to search in. Empty array means search all
 * @param locale - Language code for content localization
 * @param page - Page number for pagination
 * @param pageSize - Number of results per page
 */
export interface IGetSearchParams {
  search_term: string
  filters: TFilter[]
  locale: string
  page?: number
  pageSize?: number
}

/**
 * Available content types for searching
 * To add a new filter:
 * 1. Add the new content type string here
 * 2. Add corresponding service import at the top
 * 3. Update TServiceMap and serviceMap below
 */
export type TFilter =
  | 'editos'
  | 'news'
  | 'case-studies'
  | 'press-releases'
  | 'solutions'
  | 'white-papers'
  | 'subsidiaries'
  | 'skills'
  | 'documents'
  | 'financial-documents'

/**
 * Structure of the search results
 * Each result contains metadata and the content extract
 */
export interface IAggregatedResult {
  collectionName: string
  slug: string
  locale: string
  id: string
  date: Date
  link?: string
  extract: components['schemas']['SharedExtractComponent']
  file:
    | components['schemas']['Document']['file']
    | components['schemas']['FinancialDocument']['file']
}

/**
 * Maps content types to their corresponding service functions
 * To add a new service:
 * 1. Add new property with content type as key
 * 2. Add corresponding service function as value
 */
export type TServiceMap = {
  editos: typeof getEditoPages
  news: typeof getNewsPages
  'case-studies': typeof getCaseStudyPages
  'press-releases': typeof getPressReleasePages
  solutions: typeof getSolutionPages
  'white-papers': typeof getWhitePaperPages
  subsidiaries: typeof getSubsidiaryPages
  skills: typeof getSkillPages
  documents: typeof getDocumentPages
  'financial-documents': typeof getFinancialDocumentPages
}

/**
 * Union type of all possible return types from service functions
 * Add new service return type here when adding new content type
 */
export type TListExtract =
  | TGetCollectionTypeReturn<'editos'>
  | TGetCollectionTypeReturn<'news'>
  | TGetCollectionTypeReturn<'case-studies'>
  | TGetCollectionTypeReturn<'press-releases'>
  | TGetCollectionTypeReturn<'solutions'>
  | TGetCollectionTypeReturn<'white-papers'>
  | TGetCollectionTypeReturn<'subsidiaries'>
  | TGetCollectionTypeReturn<'skills'>
  | TGetCollectionTypeReturn<'documents'>
  | TGetCollectionTypeReturn<'financial-documents'>

export const TFilterAdapter = (
  value: string | undefined
): TFilter | undefined => {
  switch (value) {
    case 'editos':
    case 'news':
    case 'case-studies':
    case 'press-releases':
    case 'solutions':
    case 'white-papers':
    case 'subsidiaries':
    case 'skills':
    case 'documents':
    case 'financial-documents':
      return value
    default:
      return undefined
  }
}
