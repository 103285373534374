'use client'

import React from 'react'
import * as ToastPrimitives from '@radix-ui/react-toast'
import { cva, type VariantProps } from 'class-variance-authority'
import {
  X,
  XCircle,
  CheckCircle,
  WarningCircle,
  Info,
} from '@phosphor-icons/react'

import { cn } from '~@Utils/shadcnHelper'

const ToastProvider = ToastPrimitives.Provider

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      'ToasterList fixed top-none z-[100] flex max-h-screen w-full flex-col-reverse p-medium tablet:bottom-none tablet:right-none tablet:top-auto tablet:flex-col desktop:max-w-[640px]',
      className
    )}
    {...props}
  />
))
ToastViewport.displayName = ToastPrimitives.Viewport.displayName

const icon = (trigger) => {
  switch (trigger) {
    case 'error':
      return <XCircle aria-hidden="true" className="mr-standard" size={20} />
      break
    case 'success':
      return (
        <CheckCircle aria-hidden="true" className="mr-standard" size={20} />
      )
      break
    case 'warning':
      return (
        <WarningCircle aria-hidden="true" className="mr-standard" size={20} />
      )
      break
    case 'info':
      return <Info aria-hidden="true" className="mr-standard" size={20} />
      break
    default:
      return ''
      break
  }
}

const toastVariants = cva(
  "Toaster group flex gap-x-standard items-center typeface-body-2 bg-white rounded-[14px] shadow-distant box-border border-grayScale-semi border-l-4 py-standard pl-standard pr-semi before:text-[30px] before:font-['Material_Design_Icons'] relative pointer-events-auto overflow-hidden transition-all data-[swipe=cancel]:translate-x-0 data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=move]:transition-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[swipe=end]:animate-out data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full data-[state=open]:slide-in-from-top-full data-[state=open]:tablet:slide-in-from-bottom-full",
  {
    variants: {
      variant: {
        error: 'border-system-error text-system-error',
        warning: 'border-system-warning text-system-warning',
        info: 'border-system-info text-system-info',
        success: 'border-system-success text-system-success',
      },
    },
    defaultVariants: {
      variant: 'info',
    },
  }
)

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    >
      {icon(variant)}
      <span className="sr-only">{variant}</span>
    </ToastPrimitives.Root>
  )
})
Toast.displayName = ToastPrimitives.Root.displayName

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    className={cn(
      'rounded-[8px] border border-grayScale-semi bg-transparent px-small py-x-small transition-colors hover:bg-black-black/5 focus:outline-none disabled:pointer-events-none disabled:opacity-50',
      className
    )}
    {...props}
  />
))
ToastAction.displayName = ToastPrimitives.Action.displayName

const ToastClose = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Close>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Close>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Close
    ref={ref}
    className={cn(
      'absolute right-[8px] top-[8px] opacity-0 group-hover:opacity-100 transition-opacity hover:text-black text-black/50 focus:outline-none',
      className
    )}
    toast-close=""
    {...props}
  >
    <X size={20} aria-hidden="true" />
    <span className="sr-only">Fermeture</span>
  </ToastPrimitives.Close>
))
ToastClose.displayName = ToastPrimitives.Close.displayName

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn('font-bold leading-normal', className)}
    {...props}
  />
))
ToastTitle.displayName = ToastPrimitives.Title.displayName

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn('leading-normal', className)}
    {...props}
  />
))
ToastDescription.displayName = ToastPrimitives.Description.displayName

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>

type ToastActionElement = React.ReactElement<typeof ToastAction>

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastClose,
  ToastAction,
}
