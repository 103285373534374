import React from 'react'

import { IBlockPagination } from '~@Types/components/IBlockPagination'
import { UpdateNewsListAction } from '~@Organisms/filter-pagination-render/actions'
import BlockPaginationUI from '~@Molecules/block-pagination/block-pagination-ui'

export default function BlockPagination(props: IBlockPagination) {
  const click = async ({
    page,
    direction,
  }: {
    page?: number
    direction?: string
  } = {}) => {
    const pageToCall =
      page ??
      (direction === 'prev' ? props.currentPage - 1 : props.currentPage + 1)
    const updatedNewsList = await UpdateNewsListAction({
      params: {
        locale: props.locale,
        'pagination[pageSize]': props.pageSize,
        'pagination[page]': pageToCall ?? props.currentPage,
        ...(props.search ? { 'filters[title][$contains]': props.search } : {}),
        ...props.filters,
      },
    })
    if (typeof props.onPageChange === 'function') {
      props.onPageChange(updatedNewsList)
    }
    return undefined
  }

  return <BlockPaginationUI {...props} click={click} />
}
