import { ITeaserCard } from '~@Types/components/ITeaserCard'
import { BlockPictureAdapter } from '~@Adapters/BlockPictureAdapter'
import { LinkItemAdapter } from '~@Adapters/LinkItemAdapter'

import TeaserCardFormat from '~@Utils/formats/TeaserCard.json'
import dayjs from 'dayjs'
import { TNewIdAttributesFromStrapi } from '~@Types/api/TCollectionType'

export const TeaserCarAdapter = (
  contentTypePath,
  response: TNewIdAttributesFromStrapi
): ITeaserCard => {
  const datas = response?.attributes
  const title = datas?.title
  const formattedDate = dayjs(datas?.date).format('DD MMMM YYYY')
  const categories =
    datas?.news_category && datas?.news_category?.data
      ? datas?.news_category?.data
          .map((category) => category?.attributes?.label)
          .filter((label): label is string => typeof label === 'string')
      : undefined
  return {
    title: title || '',
    date: formattedDate,
    description: datas?.extract?.description || '',
    link: LinkItemAdapter({
      url: contentTypePath + '/' + datas?.slug,
    }),
    tags: categories,
    picture: datas?.extract?.picture?.picture
      ? BlockPictureAdapter(datas.extract.picture.picture, TeaserCardFormat)
      : undefined,
  }
}
