'use client'

import { useEffect, useRef } from 'react'
import Chart from 'chart.js/auto'
import { ChartConfiguration } from 'chart.js'

import { IBlockChart } from '~@Types/components/IBlockChart'
import BlockContent from '~@Organisms/block-content/block-content'

/**
 * BlockChartComponent - A component that displays data in a doughnut chart format with a corresponding list
 *
 * @param {string} title - The title of the chart block
 * @param {Array} list - Array of data items containing title and value properties
 *
 * Features:
 * - Responsive doughnut chart using Chart.js
 * - Data normalization when total exceeds 100%
 * - Maximum of 5 segments displayed
 * - Accessible chart with ARIA labels
 * - Mobile-first responsive design
 *
 * Chart Configuration:
 * - 70% cutout for doughnut style
 * - Custom border radius and spacing
 * - 1.5s animation duration
 * - Disabled tooltips and legend (handled by custom list)
 */

export default function BlockChartComponent({ title, list }: IBlockChart) {
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstance = useRef<Chart | null>(null)

  const colors = ['#E92184', '#7D003D', '#696B6D', '#BDA880', '#1F9E82']
  const MAX_SEGMENTS = 5

  useEffect(() => {
    if (chartRef.current) {
      // Destroy existing chart if it exists
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }

      const ctx = chartRef.current.getContext('2d')
      if (ctx) {
        // Normalize data if necessary
        const total = list.reduce((sum, item) => sum + item.value, 0)
        const normalizedList = list.slice(0, MAX_SEGMENTS).map((item) => ({
          ...item,
          value: total > 100 ? (item.value / total) * 100 : item.value,
        }))

        const config = {
          type: 'doughnut',
          data: {
            labels: normalizedList.map((item) => item.title),
            datasets: [
              {
                data: normalizedList.map((item) => item.value),
                backgroundColor: colors,
                borderColor: '#FFFFFF',
                borderWidth: 0,
                borderRadius: 8,
                spacing: 8,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            cutout: '70%',
            rotation: -90,
            animation: {
              duration: 1500,
            },
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              },
            },
          },
        }

        chartInstance.current = new Chart<'doughnut'>(
          ctx,
          config as ChartConfiguration<'doughnut'>
        ) as Chart
      }
    }

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy()
      }
    }
  }, [list, colors])

  return (
    <BlockContent className="BlockChart" title={title} isAlternate={true}>
      <div className="BlockChart-container flex flex-col items-center tablet:flex-row tablet:gap-5xl tablet:items-start w-full">
        <div className="BlockChart-pie-container relative w-full tablet:w-1/2">
          <div className="aspect-square w-full max-w-[500px] mx-auto">
            <canvas ref={chartRef} role="img" aria-label={title} />
          </div>
        </div>

        <ul
          className="BlockChart-list mt-md w-full tablet:w-1/2 tablet:mt-0"
          role="list"
        >
          {list.slice(0, MAX_SEGMENTS).map((item, index) => (
            <li
              key={index}
              className="BlockChart-item typeface-subtitle1-standard py-md border-b-[1px] border-solid border-grey-semi"
            >
              <span className="BlockChart-item-legend flex items-baseline">
                <span
                  className="BlockChart-item-color w-[16px] h-[16px] rounded-[2px] mr-xs shrink-0"
                  style={{ backgroundColor: colors[index] }}
                  role="presentation"
                  aria-hidden="true"
                />
                <span className="BlockChart-item-title block mb-2xs">
                  {item.title}
                </span>
              </span>
              <div className="flex items-center gap-md">
                <span className="BlockChart-item-value block pl-lg">
                  {Math.round(item.value)}%
                </span>
                <div className="w-[100px] h-[8px] rounded-[4px] overflow-hidden">
                  <div
                    className="h-full rounded-[4px] transition-all duration-[2000ms] ease-linear"
                    style={{
                      width: `${Math.round(item.value)}%`,
                      backgroundColor: colors[index],
                    }}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </BlockContent>
  )
}
