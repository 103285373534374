import ButtonSecondary from '~@Atoms/button-secondary/button-secondary'
import EditorialWysiwyg from '~@Organisms/editorial-wysiwyg/editorial-wysiwyg'

import { ISectionTextCta } from '~@Types/components/ISectionTextCta'

const SectionTextCta = ({ title, description, button }: ISectionTextCta) => {
  return (
    <div className="SectionTextCta text-center border-top border-t-[1px] border-solid border-grey-semi pt-3xl flex flex-col items-center justify-center gap-y-lg">
      <h2 className="SectionTextCta-title typeface-heading-a3-standard text-brand-primary">
        {title}
      </h2>
      {description && (
        <EditorialWysiwyg
          className="SectionTextCta-description"
          wysiwyg={description}
        />
      )}
      {button && button?.link?.href && <ButtonSecondary {...button} />}
    </div>
  )
}

export default SectionTextCta
