import { IImageConfig, IImageUrls } from '~@Types/helpers/IImage'
import { Fit } from '~@Enum/Fit'
import { Position } from '~@Enum/Position'

export const generateResponsiveImageUrls = (
  imageUrls: IImageUrls,
  imageConfig: IImageConfig
) => {
  const generatedUrls: IImageUrls = {}
  const magnification = 1.5
  Object.keys(imageUrls).forEach((key) => {
    const url = imageUrls[key]
    const params = imageConfig[key]
    if (!params) {
      generatedUrls[key] = url
    } else {
      let queryParams = ''
      if (params.width && params.height) {
        queryParams = `?resize=${Math.floor(params.width * magnification)}x${Math.floor(params.height * magnification)}`
      } else {
        if (params.width) {
          queryParams = `?width=${Math.floor(params.width * magnification)}`
        }

        if (params.height) {
          queryParams = `?height=${Math.floor(params.height * magnification)}`
        }
      }
      const fit =
        params.fit && [...(Object.values(Fit) as string[])].includes(params.fit)
          ? params.fit
          : Fit.DEFAULT
      queryParams += `&fit=${fit}`
      const position =
        params.position &&
        [...(Object.values(Position) as string[])].includes(params.position)
          ? params.position
          : Position.DEFAULT
      queryParams += `&position=${position}&format=webp`
      generatedUrls[key] = url + queryParams
    }
  })

  return generatedUrls
}
