'use client'

import dynamic from 'next/dynamic'
import { IGeoJsonItem, ILeafletMapProps } from '@/types/components/ILeafletMap'

/**
 * Dynamic import of the controlled map component to ensure client-side only rendering
 * This is necessary because Leaflet requires browser APIs not available during SSR
 */
const ClientControlledMap = dynamic<
  ILeafletMapProps & {
    selectedGroupArea: IGeoJsonItem | null
  }
>(() => import('./controlled-map'), {
  ssr: false,
})

/**
 * ControlledMap Component
 * Wrapper component that handles the dynamic import of the Leaflet map
 * @param props - Combined props from ILeafletMapProps and selectedGroupArea
 * @returns A client-side rendered Leaflet map
 */
export const ControlledMap: React.FC<
  ILeafletMapProps & {
    selectedGroupArea: IGeoJsonItem | null
  }
> = (props) => {
  return <ClientControlledMap {...props} />
}

export default ControlledMap
