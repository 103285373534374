/**
 * Converts a string to snake case format
 * Example: "Hello World" -> "hello_world"
 *
 * @param {string} str - String to convert
 * @returns {string} Converted string in snake case
 */
export const toSnakeCase = (str: string): string => {
  if (!str) return ''
  return str
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/\s+/g, '_')
    .toLowerCase()
}
