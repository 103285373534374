'use client'

// React and hooks imports
import { useState } from 'react'

// Next.js and internationalization
import { useTranslations } from 'next-intl'

// Components imports
import BlockPagination from '~@Molecules/block-pagination/block-pagination'
import FiltersSystem from '~@Molecules/filters-system/FilterSystem'
import SectionWallCms from '~@Components/cms/section-wall'

// Icons imports
import { Warning } from '@phosphor-icons/react'

// Types imports
import { IFiltersSystem } from '~@Types/components/IFiltersSystem'
import { IFilterPaginationRenderProps } from '~@Types/components/IFilterPaginationRender'
import { toSnakeCase } from '@/helpers/convertToSnakeCase'

/**
 * FilterPaginationRender Component
 * Renders a filterable and paginated list of news items
 *
 * @param {Object} props
 * @param {Array} props.newsList - Initial list of news items from the page
 * @param {Array} props.newsCategoriesList - List of available news categories
 * @param {number} props.currentPage - Current active page number
 * @param {number} props.pageSize - Number of items per page
 * @param {number} props.pageNb - Total number of pages
 * @param {string} props.locale - Current locale for internationalization
 */
export default function FilterPaginationRender({
  newsList: newsListFromPage,
  newsCategoriesList,
  currentPage: currentPageFromPage,
  pageSize,
  pageNb: pageNbFromPage,
  locale,
}: IFilterPaginationRenderProps) {
  const t = useTranslations()

  const [newsList, setNewsList] = useState(newsListFromPage)
  const [pageNb, setPageNb] = useState(pageNbFromPage)
  const [currentPage, setCurrentPage] = useState(currentPageFromPage)
  const [filters, setFilters] = useState({})
  const [search, setSearch] = useState('')

  const filtersSystemCfg: IFiltersSystem = {
    searchLabel: t('Form.searchLabel'),
    placeholder: t('Form.placeholder'),
    categoriesLabel: t('Form.categoriesLabel'),
    resetLabel: t('Form.resetBtn'),
    submitLabel: t('Form.submitLabel'),
    infoMessage: t('Form.infoMessage'),
    errorMessages: {
      minLength: t('Form.minLength'),
    },
    categories: newsCategoriesList?.map((category) => {
      return {
        name: 'categories',
        value: category.attributes?.label,
        id: toSnakeCase(String(category.attributes?.label)),
        label: category.attributes?.label,
      }
    }),
    handleForm: ({ datas, filters, search: searchUpdated }) => {
      setNewsList(datas?.data)
      setPageNb(datas?.meta?.pagination?.pageCount)
      setFilters(filters)
      setCurrentPage(1)
      setSearch(searchUpdated)
    },
    locale,
    currentPage,
    pageSize,
    pageNb,
  }

  const paginationCfg = {
    firstPage:
      currentPage > 1
        ? {
            tagElt: 'button',
            href: `?page=1`,
            children: t('Pagination.first.label'),
            ariaLabel: t('Pagination.first.ariaLabel'),
          }
        : undefined,
    lastPage:
      currentPage < pageNb
        ? {
            tagElt: 'button',
            href: `?page=${pageNb}`,
            children: t('Pagination.last.label'),
            ariaLabel: t('Pagination.last.ariaLabel'),
          }
        : undefined,
    prevLink:
      currentPage > 1
        ? {
            tagElt: 'button',
            href: `?page=${currentPage - 1}`,
            children: t('Pagination.previous.label'),
            ariaLabel: t('Pagination.previous.ariaLabel'),
          }
        : undefined,
    nextLink:
      currentPage < pageNb
        ? {
            tagElt: 'button',
            href: `?page=${currentPage + 1}`,
            children: t('Pagination.next.label'),
            ariaLabel: t('Pagination.next.ariaLabel'),
          }
        : undefined,
    pages: [...Array(pageNb)].map((_, i) => ({
      tagElt: 'button',
      href: `?page=${i + 1}`,
      children: `${i + 1}`,
      current: i + 1 === currentPage,
    })),
    pagesToDisplay: 5,
    onPageChange: (data) => {
      setNewsList(data?.data)
      setCurrentPage(data?.meta?.pagination?.page)
    },
    locale,
    currentPage,
    pageSize,
    filters,
    search,
  }

  return (
    <>
      <FiltersSystem {...filtersSystemCfg} />
      {newsList?.length ? (
        <SectionWallCms items={{ data: newsList }} />
      ) : (
        <p className="typeface-heading-a6-standard text-brand-primary flex items-start justify-center gap-md mt-4xl px-lg desktop-large:mt-8xl tablet:px-3xl">
          <Warning size={24} aria-hidden="true" />
          {t('Form.noResult')}
        </p>
      )}
      {pageNb && pageNb > 1 ? <BlockPagination {...paginationCfg} /> : ''}
    </>
  )
}
