'use client'

import { FieldValues, useForm } from 'react-hook-form'
import { IFiltersSystem } from '~@Types/components/IFiltersSystem'
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import { UpdateNewsListAction } from '~@Organisms/filter-pagination-render/actions'
import TagFilter from '~@Components/atoms/tag-filter/tag-filter'

/**
 * FiltersSystem Component
 * A form component that handles filtering and searching functionality for news items
 *
 * @component
 * @param {Object} props - Component props
 * @param {ILinkProps[]} [props.categories] - Array of category filter options
 * @param {string} [props.searchLabel] - Label for the search input field
 * @param {string} [props.placeholder] - Placeholder text for the search input
 * @param {string} [props.resetLabel] - Label for the reset button
 * @param {string} [props.categoriesLabel] - Label for the categories section
 * @param {string} props.submitLabel - Label for the submit button
 * @param {Object} [props.errorMessages] - Error message configurations
 * @param {string} [props.errorMessages.required] - Message for required field validation
 * @param {string} [props.errorMessages.minLength] - Message for minimum length validation
 * @param {Function} [props.handleForm] - Callback function triggered on form submission
 * @param {string} props.locale - Current locale for internationalization
 * @param {number} props.currentPage - Current page number
 * @param {number} props.pageSize - Number of news items per page
 * @param {number} props.pageNb - Total number of pages
 *
 * @returns {JSX.Element} A form component with search and category filtering capabilities
 *
 * @example
 * <FiltersSystem
 *   categories={[{ id: 'cat1', value: 'Category 1' }]}
 *   searchLabel="Search News"
 *   placeholder="Enter keywords..."
 *   resetLabel="Reset"
 *   categoriesLabel="Categories"
 *   submitLabel="Apply Filters"
 *   locale="en"
 *   currentPage={1}
 *   pageSize={10}
 *   pageNb={5}
 *   handleForm={({ datas, filters, search }) => {
 *     // Handle form submission
 *   }}
 * />
 */

const FiltersSystem = ({
  categories,
  categoriesLabel,
  submitLabel,
  handleForm,
  locale,
  pageSize,
}: IFiltersSystem) => {
  const { handleSubmit, setValue } = useForm()

  const computedCategoriesForFiltering = (data: Record<string, string>) => {
    const { search, ...categories } = data
    if (Object.values(categories).every((category) => category === '')) {
      return {}
    }
    return Object.values(categories).reduce((acc, category, index) => {
      return {
        ...acc,
        [`filters[news_category][label][$eq][${index}]`]: category,
      }
    }, {})
  }

  const onSubmit = async (data: FieldValues) => {
    const filters = computedCategoriesForFiltering(data)
    const search = data.search
    const updatedNewsList = await UpdateNewsListAction({
      params: {
        locale,
        'pagination[pageSize]': pageSize,
        'pagination[page]': 1,
        sort: 'date:desc',
        ...(search ? { 'filters[title][$contains]': search } : {}),
        ...filters,
      },
    })
    if (typeof handleForm === 'function') {
      handleForm({
        datas: updatedNewsList,
        filters,
        search,
      })
    }
  }

  const handleCheckboxChange = (event, filter) => {
    const checked = event.target.checked
    const value = checked ? filter.value : ''
    setValue(filter.id, value, { shouldValidate: true })
  }

  const listCategories = categories?.map((filter: any, index: number) => (
    <li key={index}>
      <TagFilter
        {...filter}
        onChange={(e) => handleCheckboxChange(e, filter)}
      />
    </li>
  ))

  return (
    <form
      className="FiltersSystem px-lg tablet:px-3xl mb-2xl"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      {categoriesLabel && (
        <p className="typeface-overline2-standard uppercase mt-lg">
          {categoriesLabel}
        </p>
      )}
      {listCategories && (
        <div className="mt-md">
          <ul className="flex flex-wrap items-center gap-xs">
            {listCategories}
          </ul>
        </div>
      )}
      <div className="mt-md text-center">
        <ButtonPrimary
          label={submitLabel}
          link={{
            tagElt: 'button',
            type: 'submit',
          }}
        />
      </div>
    </form>
  )
}

export default FiltersSystem
