'use client'

import SectionTextCta from '~@Organisms/section-text-cta/section-text-cta'

import { ISectionTextCtaCms } from '~@Types/components/ISectionTextCta'

import { SectionTextCtaAdapter } from '~@Adapters/SectionTextCtaAdapter'

export const SectionTextCtaCms = (response: ISectionTextCtaCms) => {
  if (!response) {
    return <></>
  }

  const adaptedProps = SectionTextCtaAdapter(response)
  if (!adaptedProps) {
    return <></>
  }

  return (
    <div className="mb-xl desktop:mb-5xl">
      <SectionTextCta {...adaptedProps} />
    </div>
  )
}

export default SectionTextCtaCms
