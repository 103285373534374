import { twMerge } from 'tailwind-merge'

import { IBlockContent } from '~@Types/components/IBlockContent'

const BlockContent = ({
  className,
  title,
  children,
  isAlternate,
}: IBlockContent) => {
  return (
    <section
      className={twMerge(
        'my-4xl',
        isAlternate && 'rounded-2xl shadow-md px-lg pb-lg pt-xl',
        className
      )}
      role="region"
    >
      <div className="mb-xl">
        <h2 className="typeface-heading-a5-special text-black-black mb-xl">
          {title}
        </h2>
      </div>
      {children}
    </section>
  )
}

export default BlockContent
