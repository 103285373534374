import {
  ISectionTextCta,
  ISectionTextCtaCms,
} from '~@Types/components/ISectionTextCta'
import ButtonAdapter from '~@Adapters/ButtonAdapter'
import { useGTM } from '~@Hooks/useGTM'

export const SectionTextCtaAdapter = (
  response: ISectionTextCtaCms
): ISectionTextCta => {
  if (!response?.title) {
    return {
      title: '',
      description: '',
      button: undefined,
    }
  }

  const { trackContactCTA } = useGTM()

  const buttonWithTracking = response?.link
    ? {
        ...ButtonAdapter(response.link),
        onClick: () => {
          const path =
            typeof window !== 'undefined' ? window.location.pathname : '/'
          trackContactCTA(path)
        },
      }
    : undefined

  return {
    title: response.title,
    description: response.optional_text,
    button: buttonWithTracking,
  }
}
