import { IBlockPicture, IPictureCms } from '~@Types/components/IBlockPicture'
import { generateResponsiveImageUrls } from '../helpers/generateResponsiveImageUrls'
import { IImageConfig } from '~@Types/helpers/IImage'

export const BlockPictureAdapter = (
  response: IPictureCms,
  imageFormat?: IImageConfig,
  loading?: 'lazy' | 'eager' | undefined
): IBlockPicture => {
  if (response === undefined || response === null) {
    return {
      src: {
        'desktop-large': '/img/block-picture/block-picture_desktop-large.jpg',
        desktop: '/img/block-picture/block-picture_desktop.jpg',
        tablet: '/img/block-picture/block-picture_tablet.jpg',
        mobile: '/img/block-picture/block-picture_mobile.jpg',
      },
      alt: 'Picture',
      width: 400,
      height: 400,
      loading: loading ?? 'eager',
    }
  }

  const pictureAttr: any = response?.data?.attributes
  const getAbsoluteUrl = (url) => {
    return `${process.env.NEXT_PUBLIC_STRAPI_MEDIA_URL}${url}`
  }
  const defaultPicture = getAbsoluteUrl(pictureAttr?.url)
  const formattedSrc = {
    'desktop-large': defaultPicture,
    desktop: defaultPicture,
    tablet: defaultPicture,
    mobile: defaultPicture,
  }

  return {
    src: imageFormat
      ? generateResponsiveImageUrls(formattedSrc, imageFormat)
      : formattedSrc,
    alt: response?.data?.attributes?.alternativeText
      ? response?.data?.attributes?.alternativeText
      : 'Picture',
    width: imageFormat
      ? imageFormat?.desktop?.width
      : response?.data?.attributes?.width,
    height: imageFormat
      ? imageFormat?.desktop?.height
      : response?.data?.attributes?.height,
    loading: loading ?? 'lazy',
  }
}
