import { TLinkItemCms, ILinkProps } from '~@Types/components/ILinkProps'

export const LinkItemAdapter = (response: TLinkItemCms): ILinkProps => {
  const isExternal = response?.is_external
  const hasLink = response?.url
  const formattedUrl =
    isExternal && hasLink
      ? response?.url
      : `${process.env.NEXT_PUBLIC_URL}/${response?.url}`
  return {
    tagElt: response?.url ? 'Link' : 'div',
    href: hasLink ? formattedUrl : '',
    children: response?.label,
    target: isExternal ? '_blank' : undefined,
  }
}
