import {
  IEditorialWysiwyg,
  IEditorialWysiwygCms,
} from '~@Types/components/IEditorialWysiwyg'

export const EditorialWysiwygAdapter = (
  response: IEditorialWysiwygCms
): IEditorialWysiwyg => {
  if (!response?.wysiwyg) {
    return {
      wysiwyg: '',
    }
  }
  return {
    wysiwyg: response?.wysiwyg,
  }
}
