export enum Position {
  DEFAULT = 'center',
  TOP = 'top',
  RIGHT_TOP = 'right_top',
  RIGHT = 'right',
  RIGHT_BOTTOM = 'right_bottom',
  BOTTOM = 'bottom',
  LEFT_BOTTOM = 'left_bottom',
  LEFT = 'left',
  LEFT_TOP = 'left_top',
}
