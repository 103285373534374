'use client'

import { FieldValues, useForm } from 'react-hook-form'
import { IFiltersSystemSearch } from '~@Types/components/IFiltersSystemSearch'
import LinkItem from '~@Atoms/link-item/link-item'
import TagFilter from '~@Atoms/tag-filter/tag-filter'
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import { X, Warning, MagnifyingGlass, Info } from '@phosphor-icons/react'
import { getSearchResults } from '~@Services/SearchService'
import { useEffect, useState } from 'react'
import { TFilterAdapter } from '~@Types/api/TSearchPage'

const FiltersSystemSearch = ({
  categories,
  searchLabel,
  placeholder,
  resetLabel,
  errorMessages,
  categoriesLabel,
  searchTerm: initialSearchTerm,
  submitLabel,
  infoMessage,
  handleForm,
  locale,
  initialFilters,
  currentPage,
  pageSize,
  nbResults,
}: IFiltersSystemSearch) => {
  const {
    reset,
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      search: initialSearchTerm || '',
    },
  })

  const [isFocused, setIsFocused] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(initialFilters ?? [])
  const searchTerm = watch('search') || ''

  const handleReset = () => {
    reset()
    setValue('search', '', { shouldValidate: true })
    setSelectedFilters([])
    setIsFocused(false)
  }

  const onSubmit = async (data: FieldValues) => {
    const filters = selectedFilters
      .map((filter) => TFilterAdapter(filter))
      .filter((filter) => filter !== undefined)
    const search = data.search
    const updatedList = await getSearchResults({
      filters: filters,
      locale: locale,
      search_term: search,
      page: currentPage,
      pageSize,
    })
    if (typeof handleForm === 'function') {
      handleForm({
        datas: updatedList,
        filters,
        search,
      })
    }
  }

  const labelClass =
    searchTerm || errors.search || isFocused ? 'top-none' : 'top-1/2'
  const textClass = searchTerm || errors.search || isFocused ? '' : 'w-full'

  const submitFilterChange = (filterKey: string) => {
    if (filterKey === 'ALL') {
      setSelectedFilters([])
      return
    }

    setSelectedFilters((prevFilters) => {
      if (prevFilters.includes(filterKey)) {
        return prevFilters.filter((filter) => filter !== filterKey)
      } else {
        return [...prevFilters, filterKey]
      }
    })
  }

  useEffect(() => {
    if (initialSearchTerm && initialSearchTerm !== searchTerm) {
      setValue('search', initialSearchTerm)
      onSubmit({ search: initialSearchTerm })
    }
  }, [initialSearchTerm])

  useEffect(() => {
    if (initialFilters) {
      setSelectedFilters(initialFilters)
    }
  }, [initialFilters ?? []])

  return (
    <form
      className="FiltersSystem px-lg tablet:px-3xl mb-2xl"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
    >
      <div>
        <LinkItem
          className="flex items-center gap-2xs relative z-30 ml-auto mb-xs cursor-pointer typeface-caption-special group"
          tagElt="button"
          type="button"
          onClick={handleReset}
        >
          <X
            size={16}
            weight="bold"
            className="mb-3xs transition-transform group-hover:rotate-180"
          />
          {resetLabel}
        </LinkItem>
        <div className="relative z-20 typeface-subtitle2-standard">
          {searchLabel && (
            <label
              htmlFor="search"
              className={`absolute transition-all duration-250 ${labelClass} transform -translate-y-1/2 h-full w-full flex gap-2xs items-center pl-xs pr-md border-box ${errors.search ? `text-system-danger` : ''}`}
            >
              <span className={`bg-white-white px-xs ${textClass}`}>
                {searchLabel}
              </span>
            </label>
          )}
          <input
            placeholder={placeholder || ''}
            className={`bg-white-white border-xs py-md pl-md pr-3xl rounded-xl focus:text-inherit focus:outline-black-black focus:outline-2 focus:outline-offset-2 w-full ${
              errors.search
                ? `text-system-danger/50 border-system-danger`
                : `text-black-60 border-grey-semi`
            }`}
            type="text"
            id="search"
            aria-describedby="input-description"
            value={searchTerm}
            {...register('search', {
              minLength: {
                value: 3,
                message: errorMessages?.minLength || 'Default error message',
              },
            })}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <MagnifyingGlass
            size={24}
            aria-hidden="true"
            className="text-black-black absolute top-[50%] right-md -translate-y-[50%]"
          />
        </div>
        {!errors.search ? (
          <p
            className="flex items-center gap-xs my-xs text-grayScale-dark typeface-caption-special"
            id="input-description"
          >
            <Info size={16} /> {infoMessage}
          </p>
        ) : (
          <p
            aria-describedby="search"
            className="flex items-center gap-xs my-xs text-system-danger typeface-caption-special"
          >
            <Warning size={16} />{' '}
            {errorMessages?.[errors.search.type as string]}
          </p>
        )}
      </div>
      {categoriesLabel && (
        <p className="typeface-overline2-standard uppercase mt-lg">
          {categoriesLabel}
        </p>
      )}
      {categories && (
        <div className="mt-md tablet:flex block gap-y-sm items-start ">
          <ul className="flex flex-1 flex-wrap items-center gap-xs">
            {categories.map((filter: any, index: number) => (
              <li key={index}>
                <TagFilter
                  {...filter}
                  checked={
                    filter.id === 'ALL'
                      ? selectedFilters.length === 0
                      : selectedFilters.includes(filter.id)
                  }
                  onChange={(e) => submitFilterChange(filter.id)}
                />
              </li>
            ))}
          </ul>
          {nbResults && (
            <p className="text-brand-primary typeface-subtitle1-standard tablet:pl-xl tablet:ml-xl tablet:py-xs pt-xl tablet:border-l-[2px] border-grey-semi">
              {nbResults}
            </p>
          )}
        </div>
      )}
      <div className="mt-md text-center">
        <ButtonPrimary
          label={submitLabel}
          link={{
            tagElt: 'button',
            type: 'submit',
          }}
        />
      </div>
    </form>
  )
}

export default FiltersSystemSearch
