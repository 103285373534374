'use client'

import BlockPicture from '~@Atoms/block-picture/block-picture'
import ButtonPrimary from '~@Atoms/button-primary/button-primary'
import BreadCrumb from '~@Atoms/breadcrumb/Breadcrumb'

import { ISectionHeroAlternate } from '~@Types/components/ISectionHero'
import { useEffect, useState } from 'react'

export default function SectionHeroAlternate({
  tag,
  title,
  label,
  picture,
  breadCrumb,
  logo,
  children,
  link,
  enableAnchor = true,
}: ISectionHeroAlternate) {
  const buttonPrimaryCfg = {
    link: {
      href: '#content',
      tagElt: 'Link',
    },
    isAnchor: true,
    label: label,
  }

  const [isMobile, setIsMobile] = useState(false)

  /**
   * Updates mobile state based on viewport width
   * Breakpoint set at 960px
   */
  const handleResize = () => {
    window.innerWidth < 960 ? setIsMobile(true) : setIsMobile(false)
  }

  useEffect(() => {
    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isMobile])

  return (
    <div className="SectionHeroAlternate mb-xl desktop-large:mb-5xl">
      <div className="overflow-hidden">
        <div className="SectionHeroAlternate-wrapper items-baseline relative container px-lg desktop-large:px-3xl desktop-large:grid desktop-large:grid-cols-12 desktop-large:grid-rows-[75px] desktop-large:gap-x-xl desktop-large:min-h-[642px]">
          <div className="SectionAlternate-subheader desktop-large:relative desktop-large:h-[75px] desktop-large:col-span-4">
            <span
              className="SectionHeroAlternate-subheader-corner hidden desktop-large:block desktop-large:absolute desktop-large:z-20 desktop-large:h-[32px] desktop-large:w-[32px] desktop-large:bottom-none desktop-large:right-none desktop-large:translate-x-[calc(100%-8px)] desktop-large:overflow-hidden desktop-large:before:block desktop-large:before:content[''] desktop-large:before:h-[32px] desktop-large:before:w-[32px] desktop-large:before:rounded-bl-[60%] desktop-large:before:shadow-[0_0_0_32px_white]"
              aria-hidden="true"
            />
          </div>
          {picture ? (
            <div className="SectionAlternate-picture-wrapper mb-xl desktop-large:px-lg desktop-large:grid desktop-large:grid-cols-12 desktop-large:gap-x-xl desktop-large:absolute desktop-large:right-none desktop-large:top-none desktop-large:h-full">
              <div className="desktop-large:col-start-5 desktop-large:col-end-13 desktop-large:-translate-x-xl">
                <div className='SectionHeroAlternate-picture w-[calc(100%+24px)] desktop-large:w-[125%] min-[1805px]:w-[150%] min-[2200px]:w-[170%] relative before:absolute before:top-none before:left-none before:rounded-br-2xl before:content[""] before:bg-white-white before:h-[70px] tablet:before:h-[80px] desktop-large:before:h-[100px] before:w-[90px] tablet:before:w-[130px] desktop-large:before:w-[160px] desktop-large:before:hidden desktop-large:h-full'>
                  <BlockPicture
                    {...picture}
                    className="SectionHeroAlternate-picture-image w-full rounded-l-xl mb-xs desktop-large:object-cover"
                  />
                  {logo && (
                    <div className="SectionHeroAlternate-picture-logo absolute z-20 flex items-center justify-center top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 max-w-[280px] max-h-[200px] desktop:max-w-[458px] desktop:max-h-[340px] desktop-large:left-[70%]">
                      <BlockPicture className="w-auto max-w-full" {...logo} />
                    </div>
                  )}
                  <span
                    className="SectionHeroAlternate-picture-corner block absolute top-none left-[90px] tablet:left-[130px] before:rounded-tl-[60%] desktop-large:top-[64px] desktop-large:left-none desktop-large:before:rounded-bl-[60%] desktop-large:before:rounded-tl-none h-[32px] w-[32px] overflow-hidden before:block before:content[''] before:h-[32px] before:w-[32px] before:shadow-[0_0_0_32px_white] desktop-large:before:hidden"
                    aria-hidden="true"
                  />
                  <span
                    className="SectionHeroAlternate-subcorner block absolute h-[32px] w-[32px] top-[70px] tablet:top-[80px] desktop-large:top-auto desktop-large:bottom-[174px] desktop-large:-translate-y-[25%] left-none overflow-hidden before:block before:content[''] before:h-[32px] before:w-[32px] before:rounded-tl-[60%] before:shadow-[0_0_0_32px_white] desktop-large:before:hidden"
                    aria-hidden="true"
                  />
                </div>
                {breadCrumb && (
                  <BreadCrumb
                    ariaHidden={!isMobile}
                    className="SectionHeroAlternate-breadcrumb my-xs desktop-large:hidden"
                    {...breadCrumb}
                  />
                )}
              </div>
            </div>
          ) : null}
          <div className="SectionHeroAlternate-heading relative bg-white-white rounded-r-xl desktop-large:py-3xl pr-xl desktop-large:col-span-7 desktop-large:row-start-2">
            {tag && (
              <p className="SectionHeroAlternate-tag mb-lg pr-md text-brand-primary typeface-subtitle1-standard desktop-large:mb-none desktop-large:max-w-[210px] desktop-large:truncate desktop-large:absolute desktop-large:left-none desktop-large:translate-y-[75%] desktop-large:top-xl desktop-max:top-4xl">
                • {tag}
              </p>
            )}
            <h1 className="SectionHeroAlternate-title bg-gradient-text typeface-heading-a2-standard mb-md desktop:text-[50px] desktop-large:indent-[210px] desktop-large:mb-none desktop-max:text-[67px]">
              {title}
            </h1>
            {enableAnchor && (
              <ButtonPrimary
                className="SectionHeroAlternate-action desktop-large:absolute desktop-large:bottom-none desktop-large:translate-y-1/2"
                {...buttonPrimaryCfg}
              />
            )}
            <span
              className="SectionHeroAlternate-extra-corner hidden desktop-large:block desktop-large:absolute desktop-large:h-[32px] desktop-large:w-[32px] desktop-large:top-full desktop-large:right-[45%] desktop-large:translate-x-[calc(100%-8px)] desktop-large:overflow-hidden desktop-large:before:block desktop-large:before:content[''] desktop-large:before:h-[32px] desktop-large:before:w-[32px] desktop-large:before:rounded-tl-[60%] desktop-large:before:shadow-[0_0_0_32px_white]"
              aria-hidden="true"
            />
          </div>
          <div className="SectionHeroAlternate-extra relative desktop-large:col-span-4 desktop-large:flex desktop-large:flex-col desktop-large:justify-between desktop-large:row-start-3 mt-auto">
            {link && <ButtonPrimary {...link} />}
            {children && (
              <div className="SectionHeroAlternate-tags">{children}</div>
            )}
          </div>
        </div>
      </div>
      {breadCrumb && (
        <div className="hidden desktop-large:px-lg desktop-large:grid desktop-large:grid-cols-12 desktop-large:gap-x-xl px-lg container">
          <div className="desktop-large:col-start-5 desktop-large:col-end-13 desktop-large:-translate-x-xl relative">
            <span
              className=" block z-1 absolute h-[32px] w-[32px] left-none top-none -translate-y-full overflow-hidden before:block before:content[''] before:h-[32px] before:w-[32px] before:rounded-bl-[60%] before:shadow-[0_0_0_32px_white]"
              aria-hidden="true"
            />
            <BreadCrumb
              ariaHidden={isMobile}
              className="SectionHeroAlternate-breadcrumb my-xs"
              {...breadCrumb}
            />
          </div>
        </div>
      )}
    </div>
  )
}
