'use client'

// External dependencies
import React, { useEffect, useState } from 'react'
import { useTranslations } from 'next-intl'

/**
 * Interface representing the structure of a heading in the document
 */
interface HeadingData {
  /** Unique identifier for the heading */
  id: string
  /** Text content of the heading */
  text: string
}

/**
 * Props for the AnchorMenuGenerator component
 */
interface AnchorMenuGeneratorProps {
  /** Initial delay before menu generation (in ms) */
  initialDelay?: number
  /** Vertical offset when scrolling to a section (in px) */
  scrollOffset?: number
}

/**
 * AnchorMenuGenerator Component
 *
 * Generates a navigation menu that automatically tracks and highlights h2 headings
 * within a content area. It provides smooth scrolling to sections with an offset
 * and visual feedback for the currently visible section.
 *
 * Features:
 * - Automatically generates navigation from h2 headings
 * - Tracks visible sections using IntersectionObserver
 * - Provides smooth scrolling to sections with configurable offset
 * - Highlights currently visible section
 * - Supports manual and automatic scroll tracking
 * - Maintains clicked section highlight during scroll
 *
 * @param {number} initialDelay - Delay before initial menu generation (default: 100ms)
 * @param {number} scrollOffset - Vertical offset when scrolling to section (default: 100px)
 * @returns {JSX.Element | null} Returns the navigation menu or null if less than 2 headings
 */
const AnchorMenuGenerator = ({
  initialDelay = 100,
  scrollOffset = 100,
}: AnchorMenuGeneratorProps) => {
  const [headingsData, setHeadingsData] = useState<HeadingData[]>([])
  const [activeId, setActiveId] = useState<string | null>(null)
  const [isManualScroll, setIsManualScroll] = useState(false)
  const [clickedId, setClickedId] = useState<string | null>(null)
  const t = useTranslations()

  const handleClick = (id: string) => {
    setClickedId(id)
    setActiveId(id)
    setIsManualScroll(true)

    const element = document.getElementById(id)
    if (element) {
      const elementPosition = element.getBoundingClientRect().top
      const offsetPosition = elementPosition + window.scrollY - 150

      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })

      setTimeout(() => {
        setIsManualScroll(false)
        setClickedId(null)
      }, 1200)
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      const contentElement = document.querySelector('#content')
      if (!contentElement) return
      const headingElements = Array.from(contentElement.querySelectorAll('h2'))

      const data = headingElements
        .filter(
          (heading) => heading.textContent && heading.textContent.trim() !== ''
        )
        .map((heading, index) => {
          const textContent = heading.textContent || ''
          const id = `${heading.id || textContent.replace(/\s+/g, '-').toLowerCase()}-${index}`
          const closestSection = heading.closest('section')

          if (closestSection) {
            closestSection.id = id
          } else {
            heading.id = id
          }

          return {
            id,
            text: textContent,
          }
        })

      setHeadingsData(data)

      const observerOptions = {
        root: null,
        rootMargin: '-20% 0px -60% 0px',
        threshold: [0, 0.25, 0.5, 0.75, 1],
      }

      const observerCallback: IntersectionObserverCallback = (entries) => {
        if (isManualScroll || clickedId) return

        const visibleSections = entries
          .filter((entry) => entry.isIntersecting)
          .sort((a, b) => {
            const aTop = a.boundingClientRect.top
            const bTop = b.boundingClientRect.top

            if (Math.abs(aTop) < 100 || Math.abs(bTop) < 100) {
              return aTop - bTop
            }

            if (Math.abs(a.intersectionRatio - b.intersectionRatio) < 0.1) {
              return aTop - bTop
            }
            return b.intersectionRatio - a.intersectionRatio
          })

        if (visibleSections.length > 0) {
          const mostVisible = visibleSections[0].target
          if (mostVisible?.id) {
            setActiveId(mostVisible.id)
          }
        }
      }

      const observer = new IntersectionObserver(
        observerCallback,
        observerOptions
      )

      headingElements.forEach((heading) => {
        const section = heading.closest('section')
        observer.observe(section || heading)
      })

      return () => {
        headingElements.forEach((heading) => {
          const section = heading.closest('section')
          observer.unobserve(section || heading)
        })
      }
    }, initialDelay)

    return () => clearTimeout(timer)
  }, [initialDelay, isManualScroll, clickedId])

  if (headingsData.length <= 1) return null

  return (
    <nav
      className="anchor-menu bg-grey-x-light rounded-2xl py-lg desktop-large:mr-8xl desktop-large:sticky desktop-large:top-7xl desktop-large:z-30"
      aria-label={t('SummaryMenu.ariaLabel')}
    >
      <h3 className="typeface-caption-standard mb-sm px-lg text-black-60">
        {t('SummaryMenu.title')}
      </h3>
      <ul>
        {headingsData.map((heading) => (
          <li
            key={heading.id}
            className="relative typeface-subtitle2-standard text-black-black"
          >
            <a
              href={`#${heading.id}`}
              className={`group px-lg py-xs inline-block before:transition-all before:content[""] before:absolute before:top-none before:left-none before:h-full before:bg-black-black ${
                activeId === heading.id
                  ? 'typeface-subtitle2-special before:w-2xs'
                  : 'before:w-none'
              }`}
              onClick={(e) => {
                e.preventDefault()
                handleClick(heading.id)
              }}
            >
              <span
                className={
                  activeId === heading.id
                    ? ''
                    : "relative group-hover:before:w-full before:absolute before:content-[''] before:-bottom-3xs before:left-none before:h-4xs before:bg-[currentColor] before:pointer-events-none before:w-none before:transition-[width] before:duration-900 before:ease-[cubic-bezier(.135,.9,.15,1)]"
                }
              >
                <span className="line-clamp-1">{heading.text}</span>
              </span>
            </a>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export default AnchorMenuGenerator
