'use client'
import React, { useState } from 'react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import { cn } from '~@Utils/shadcnHelper'
import { Minus, Plus } from '@phosphor-icons/react'

const Accordion = (props) => (
  <AccordionPrimitive.Root type="multiple" {...props} />
)

const AccordionItem = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={className} {...props} />
))
AccordionItem.displayName = 'AccordionItem'

const AccordionTrigger = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleClick = () => {
    setIsOpen((prev) => !prev)
  }

  return (
    <AccordionPrimitive.Header className="flex">
      <AccordionPrimitive.Trigger
        ref={ref}
        className={cn(
          'typeface-subtitle1-special border-t-[1px] border-grey-light flex items-center justify-between gap-x-sm cursor-pointer w-full mb-lg pt-lg',
          className
        )}
        aria-label={children?.toString() || 'accordion'}
        onClick={handleClick}
        {...props}
      >
        <span className="text-left">{children}</span>
        <div
          className={`transition-all ease-out duration-250 px-sm py-xs rounded-full border-[1px] ${isOpen ? 'text-white-white border-brand-primary bg-brand-primary' : 'border-grey-semi text-brand-primary'}`}
        >
          {isOpen ? (
            <Minus aria-label="collapse icon" size={24} />
          ) : (
            <Plus aria-label="expand icon" size={24} />
          )}
        </div>
      </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
  )
})
AccordionTrigger.displayName = 'AccordionTrigger'

const AccordionContent = React.forwardRef<
  React.ElementRef<typeof AccordionPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className="transition-all ease-out duration-250 overflow-hidden data-[state=closed]:animate-accordion-up data-[state=open]:animate-accordion-down"
    {...props}
  >
    <div className={cn('pb-lg', className)}>{children}</div>
  </AccordionPrimitive.Content>
))
AccordionContent.displayName = 'AccordionContent'

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent }
