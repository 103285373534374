import ButtonDownload from '~@Components/atoms/button-download/button-download'
import { ITeaserDownload } from '~@Types/components/IDownload'

const TeaserDownload = ({ title, type, weight, link }: ITeaserDownload) => {
  return (
    <div className="TeaserDownload py-md flex gap-md items-center justify-between typeface-subtitle1-standard tablet:gap-xl">
      <p className="TeaserDownload-title typeface-subtitle1-special line-clamp-4 shrink-0 w-1/2">
        {title}
      </p>
      <div className="flex items-center justify-end gap-md shrink-0 w-1/2 tablet:gap-xl">
        <p className="TeaserDownload-type uppercase">{type}</p>
        <ButtonDownload {...link} ariaLabel={title} />
      </div>
    </div>
  )
}

export default TeaserDownload
