import { DownloadSimple } from '@phosphor-icons/react/dist/ssr'
import { twMerge } from 'tailwind-merge'

import LinkItem from '~@Atoms/link-item/link-item'

import { ILinkProps } from '~@Types/components/ILinkProps'

export const ButtonDownload = (link: ILinkProps, ariaLabel?: string) => {
  const colorStyle = link?.isAlternate
    ? 'border-brand-primary text-brand-primary hover:bg-brand-primary hover:text-white-white p-xs'
    : 'bg-black-black text-white-white p-md hover:border-black-black hover:text-black-black hover:bg-white-white'
  return (
    <LinkItem
      {...link}
      className={twMerge(
        `ButtonDownload group inline-flex items-center gap-x-xs rounded-full border-[1px] typeface-subtitle2-standard border-solid ${colorStyle}`,
        link.className
      )}
    >
      {link?.children && (
        <span className="flex items-center gap-2xs">{link.children}</span>
      )}
      <DownloadSimple
        className="transition-transform ease-in-out duration-300 group-hover:transform group-hover:translate-y-4xs"
        size={16}
        weight="bold"
        role="img"
        {...(ariaLabel && { 'aria-label': ariaLabel })}
      />
    </LinkItem>
  )
}

export default ButtonDownload
