'use client'

import React, { useState, useEffect, useCallback, useMemo } from 'react'
import { useLocale, useTranslations } from 'next-intl'
import { CaretLeft, CaretRight } from '@phosphor-icons/react'

import BlockContent from '~@Organisms/block-content/block-content'
import TeaserDownload from '~@Molecules/teaser-download/teaser-download'
import BlockPaginationUI from '~@Molecules/block-pagination/block-pagination-ui'

import { IBlockFinancialDocuments } from '~@Types/components/IDownload'

import { useHorizontalScroll } from '~@Hooks/useHorizontalScroll'
import { useGTM } from '~@Hooks/useGTM'

export const BlockFinancialDocuments = React.memo(
  function BlockFinancialDocuments({ title, list }: IBlockFinancialDocuments) {
    const t = useTranslations()
    const locale = useLocale()
    const filteredList = list.filter((category) => category.list.length > 0)
    const [currentPage, setCurrentPage] = useState(1)
    const ITEMS_PER_PAGE = 6

    const [activeTab, setActiveTab] = useState(filteredList[0]?.name || '')
    const { containerRef, showLeftArrow, showRightArrow, scrollTo } =
      useHorizontalScroll()

    const activeCategory = useMemo(() => {
      return filteredList.find((category) => category.name === activeTab)
    }, [filteredList, activeTab])

    useEffect(() => {
      setCurrentPage(1)
    }, [activeTab])

    const paginationData = useMemo(() => {
      if (!activeCategory) return null

      const totalItems = activeCategory.list.length
      if (totalItems < 1) return null

      const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE)
      const startIndex = (currentPage - 1) * ITEMS_PER_PAGE
      const endIndex = startIndex + ITEMS_PER_PAGE
      const currentItems = activeCategory.list.slice(startIndex, endIndex)

      return {
        totalPages,
        currentItems,
        paginationProps: {
          firstPage: {
            children: t('Pagination.first.label'),
            tagElt: 'button',
            ariaLabel: t('Pagination.first.ariaLabel'),
            type: 'button',
          },
          lastPage: {
            children: t('Pagination.last.label'),
            tagElt: 'button',
            ariaLabel: t('Pagination.last.ariaLabel'),
            type: 'button',
          },
          prevLink:
            currentPage > 1
              ? {
                  children: t('Pagination.previous.label'),
                  tagElt: 'button',
                  ariaLabel: t('Pagination.previous.ariaLabel'),
                  type: 'button',
                }
              : undefined,
          nextLink:
            currentPage < totalPages
              ? {
                  children: t('Pagination.next.label'),
                  tagElt: 'button',
                  ariaLabel: t('Pagination.next.ariaLabel'),
                  type: 'button',
                }
              : undefined,
          currentPage,
          pages: Array.from({ length: totalPages }, (_, i) => ({
            children: String(i + 1),
            tagElt: 'button',
            type: 'button',
            current: i + 1 === currentPage,
            ariaCurrent: i + 1 === currentPage,
          })),
          locale,
          pageSize: ITEMS_PER_PAGE,
        },
      }
    }, [activeCategory, currentPage, t, locale])

    const handlePaginationClick = useCallback(
      ({ page, direction }: { page?: number; direction?: 'prev' | 'next' }) => {
        if (page) {
          setCurrentPage(page)
        } else if (direction === 'prev') {
          setCurrentPage((prev) => Math.max(1, prev - 1))
        } else if (direction === 'next' && paginationData) {
          setCurrentPage((prev) =>
            Math.min(paginationData.totalPages, prev + 1)
          )
        }
      },
      [paginationData]
    )

    const { trackDownload } = useGTM()

    const handleDownloadClick = (docName: string, docType: string) => {
      trackDownload(docName, docType)
    }

    return (
      <div className="mb-xl desktop:mb-5xl" tabIndex={-1}>
        <BlockContent className="SectionDownloads" title={title || ''}>
          <div className="flex items-start mb-lg relative">
            {showLeftArrow && (
              <button
                onClick={() => scrollTo('left')}
                aria-label={t('FinancialDocuments.scroll.left')}
                className="p-xs rounded-full hover:bg-grey-light z-10"
                tabIndex={0}
              >
                <CaretLeft />
              </button>
            )}
            <div
              ref={containerRef as React.RefObject<HTMLDivElement | null>}
              className={`SectionDownloads-tabs flex gap-x-xl overflow-x-auto scrollbar-hide relative
                ${showLeftArrow ? '[mask-image:linear-gradient(to_right,transparent,black_5%,black_95%,transparent)]' : ''}
                ${showRightArrow ? '[-webkit-mask-image:linear-gradient(to_right,transparent,black_5%,black_95%,transparent)]' : ''}`}
              role="tablist"
              aria-label={t('FinancialDocuments.tablist.label')}
            >
              {filteredList.map((category) => (
                <button
                  key={category.name}
                  onClick={() => setActiveTab(category.name)}
                  role="tab"
                  aria-selected={activeTab === category.name}
                  aria-controls={`panel-${category.name}`}
                  id={`tab-${category.name}`}
                  className={`typeface-button1-special py-sm whitespace-nowrap ${
                    activeTab === category.name
                      ? 'text-brand-primary border-b-sm border-brand-primary'
                      : ''
                  }`}
                  tabIndex={0}
                >
                  {category.name}
                </button>
              ))}
            </div>
            {showRightArrow && (
              <button
                onClick={() => scrollTo('right')}
                aria-label={t('FinancialDocuments.scroll.right')}
                className="p-xs rounded-full hover:bg-grey-light z-10"
                tabIndex={0}
              >
                <CaretRight />
              </button>
            )}
          </div>
          {filteredList
            .filter((category) => category.name === activeTab)
            .map((category) => (
              <div
                key={category.name}
                role="tabpanel"
                id={`panel-${category.name}`}
                aria-labelledby={`tab-${category.name}`}
              >
                <ul
                  className="SectionDownloads-list"
                  aria-label={t('FinancialDocuments.documentList.label')}
                >
                  {paginationData?.currentItems.map((item, index) => {
                    const formattedItem = {
                      ...item,
                      link: {
                        ...item.link,
                        onClick: () =>
                          handleDownloadClick(item.title, item.type),
                      },
                    }

                    return (
                      <li
                        key={index}
                        className="border-b-xs border-b-solid border-grey-light"
                      >
                        <TeaserDownload {...formattedItem} />
                      </li>
                    )
                  })}
                </ul>
                {paginationData && (
                  <BlockPaginationUI
                    {...paginationData.paginationProps}
                    click={handlePaginationClick}
                  />
                )}
              </div>
            ))}
        </BlockContent>
      </div>
    )
  }
)
