import { TLinkItemCms } from '~@Types/components/ILinkProps'
import { IButton } from '~@Types/components/IButton'

import { sanitizeUrl } from 'src/helpers/sanitizeUrl'

export const ButtonAdapter = (response: TLinkItemCms): IButton => {
  const isExternal = response?.is_external
  const hasLink = !!response?.url
  const formattedUrl =
    hasLink && !isExternal
      ? `${process.env.NEXT_PUBLIC_URL}/${sanitizeUrl(response?.url)}`
      : response?.url
  return {
    link: {
      tagElt: hasLink ? 'Link' : 'div',
      href: hasLink ? formattedUrl : '',
      target: isExternal === true ? '_blank' : '',
    },
    label: response?.label,
  }
}

export default ButtonAdapter
