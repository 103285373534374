'use client'

import { ITeaserArgument } from '~@Types/components/ITeaserArgument'
import { Check } from '@phosphor-icons/react'

const TeaserArgument = ({ title, description }: ITeaserArgument) => {
  return (
    <article className="TeaserArgument flex items-start text-black-black">
      <div
        className="text-white-white rounded-full bg-brand-secondary mr-sm p-2xs mt-2xs"
        aria-hidden="true"
      >
        <Check size={16} />
      </div>
      <div>
        {title && (
          <h3 className="TeaserArgument-title typeface-body1-special">
            {title}
          </h3>
        )}
        {description && (
          <p className="TeaserArgument-desc typeface-body1-standard">
            {description}
          </p>
        )}
      </div>
    </article>
  )
}

export default TeaserArgument
