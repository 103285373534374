'use client'

import { useEffect, useCallback } from 'react'

import { useGTM } from '~@Hooks/useGTM'

import { IGTMSearchTracker } from '~@Types/components/IGTM'

export default function GTMSearchTracker({
  terms,
  hasResults,
}: IGTMSearchTracker) {
  const { trackSearch } = useGTM()

  const handleSearchResults = useCallback(() => {
    trackSearch(terms, hasResults)
  }, [terms, hasResults])

  useEffect(() => {
    handleSearchResults()
  }, [handleSearchResults])

  return null
}
