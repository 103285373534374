import { ITagFilter } from '~@Types/components/ITagFilter'

export default function TagFilter({
  name,
  id,
  value,
  label,
  onChange,
  checked,
}: ITagFilter) {
  return (
    <div className="TagFilter">
      <input
        type="checkbox"
        name={name ? name : ''}
        id={id ? id : ''}
        value={value}
        checked={checked}
        className="sr-only peer"
        onChange={onChange ? onChange : undefined}
      />
      <label
        htmlFor={id ? id : ''}
        className="cursor-pointer rounded-full border-xs py-xs px-sm typeface-subtitle2-standard text-brand-primary border-brand-primary transition-colors ease-in-out duration-300 peer-checked:bg-brand-primary peer-checked:text-white-white hover:bg-brand-primary/15 peer-focus:ring-black-black peer-focus:ring-1"
      >
        {label}
      </label>
    </div>
  )
}
